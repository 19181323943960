.nav {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  z-index: 99999999999;
  transform: scaleY(1);
  justify-content: space-between;
  background: var(--navbar-color);
}

.navCurr {
  color: var(--primary-color);
}

.navCurr::before {
  content: "";
  position: absolute;
  bottom: -6px;
  margin: 0 auto;
  left: -22px;
  right: 0;
  width: 80%;
  border: 1px solid #ffac00;
  background-color: orange;
  animation-timing-function: ease-out;
}

.nav .name {
  color: var(--primary-color);
  font-size: 1.8em;
  min-height: 0;
  user-select: none;
  padding-left: 15px;
  font-family: var(--navbar-name-font);
}

.links {
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 1.1em;
}

.links .link {
  position: relative;
  padding-right: 25px;
  cursor: pointer;
}

.link button::before {
  content: "";
  position: absolute;
  bottom: -4px;
  margin: 0 auto;
  left: -22px;
  right: 0;
  width: 0%;
  display: flex;
  height: 1px;
  transition: 0.4s;
}

.burger {
  color: white;
  padding-right: 10px;
}

.burger .menu-icons {
  font-size: 2em;
}

.link button:hover::before {
  width: 60%;
  background-color: orange;
}

.nav-links.show {
  position: fixed;
  top: 60px;
  z-index: 9999999999;
  display: block;
  width: 100%;
}

.nav-links {
  height: 34px;
  transition: 0.4s;
  margin: 2px;
  background: #1c2128;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.nav-links.hide {
  display: none;
  height: 0;
}

.nav-links > div {
  background: #1c2128;
  text-decoration: none;
  color: white;
  padding: 8px;
  border: none;
}
