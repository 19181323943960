.intro {
    position: relative;
    height: calc(100vh - 16px);
    background: var(--intro-canvas);
    user-select: none;
  }

  #particles-js {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  .intro .content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .p1 {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 1000;
    letter-spacing: 2px;
    margin-bottom: 20px;
    display: flex;
  }
  
  .p2 {
    display: flex;
    font-size: 68px;
    font-family: var(--cursive);
    letter-spacing: 3px;
    color: white;
    font-weight: bold;
  }
  
  .p3 {
    font-size: 27px;
    margin-top: 20px;
    font-weight: 1000;
    color: white;
  }
  
  .p3 span {
    color: var(--primary-color);
  }

  .trans-button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%);
  }

  .trans-button span {
    font-size: 70px;
    font-weight: 600;
    color: var(--primary-color);
    animation: 3s ease infinite animate;
  }

  @keyframes animate {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(60px);
    }
  }