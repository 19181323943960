.projects-heading {
  margin: 60px 0px;
  text-align: center;
}
.projects-heading h1 {
  font-size: 4rem;
  font-family: var(--normal-text-font);
  color: rgb(221, 221, 221);
}

.project-content {
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.project-content .card {
  position: relative;
  padding: 10px;
  width: 380px;
  margin: 30px;
  background: #3a3a3a;
  height: 450px;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  flex-direction: column;
}

/* .project-content .card:hover {
  background: var(--primary-color);
}*/

.project-content .card .inner-style-cont {
  position: absolute;
  top: -23px;
  right: -23px;
  height: 40px;
  width: 40px;
  border-radius: 0% 50% 0% 50%;
  background: var(--primary-color);
  transition: 1s;
  overflow: hidden;
}

.project-content .card .icon,
.project-content .card h2,
.project-content .card .line,
.project-content .card .tech-stack,
.project-content .card .description,
.project-content .card .links {
  z-index: 1;
  transition: 1s;
}

.project-content .card:hover > div.inner-style-cont {
  right: 0%;
  border-radius: 0%;
  height: calc(100% + 23px);
  width: 100%;
  z-index: 0;
}

.project-content .card:hover .icon span {
  color: white !important;
}

.project-content .card:hover .links h4 a {
  color: white !important;
  text-decoration: underline;
  text-decoration-color: white;
}

.project-content .card:hover h2,
.project-content .card:hover .tech-stack,
.project-content .card:hover .description {
  color: black;
}

.project-content .card:hover .line {
  background: white;
}

.project-content .card span {
  margin-top: 15px;
  font-size: 60px;
  color: var(--primary-color);
}

.project-content h2 {
  color: white;
  margin: 15px 0px;
}

.project-content .line {
  width: 20px;
  height: 2px;
  left: 50%;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
}

.project-content .tech-stack {
  margin-top: 10px;
  color: var(--green-color);
  text-transform: capitalize;
  font-weight: 700;
}

.project-content .description {
  margin-top: 16px;
  font-size: 18px;
  color: white;
}

.project-content .links {
  margin-top: 21px;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.links h4 a {
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: underline;
}
