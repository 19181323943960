.about {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background: var(--intro-canvas);
}

.intro-image img {
  height: 400px;
  width: 300px;
  border-radius: 10px;
}

.intro-table {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 45vw;
}
.personal-detail {
  display: flex;
  text-decoration: none;
  margin-bottom: 20px;
}
.personal-detail li {
  margin-bottom: 20px;
  margin-right: 50px;
  font-size: 18px;
  font-weight: 600;
  list-style: none;
}

.personal-detail .head {
  color: var(--green-color) !important;
}

.personal-detail .detail {
  color: white;
}

.intro-table h2 {
  color: white;
  font-size: 1.6em;
  color: #b97c0c;
  margin-bottom: 20px;
}

.about-heading {
  font-size: 50px;
  font-weight: 1000;
  color: white;
  margin-bottom: 20px;
}

.download-button {
  width: fit-content;
  border-radius: 25px;
  padding: 15px;
  box-shadow: 1px 1px 3px white;
  background: var(--primary-color);
}

.download-button a {
  text-decoration: none;
  color: var(--intro-canvas);
  font-weight: 900;
  font-size: medium;
}
