.music1,
.music3 {
  height: 20px;
  width: 4px;
  margin: 2px;
  background: var(--primary-color);
}

.music2 {
  height: 40px;
  width: 4px;
  margin: 2px;
  background: var(--primary-color);
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.music1,
.music3 {
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes musicAnimeLong {
  0% {
    height: 25px;
  }
  50% {
    height: 30px;
  }
  100% {
    height: 25px;
  }
}

@keyframes musicAnimeShort {
  0% {
    height: 10px;
  }
  50% {
    height: 20px;
  }
  100% {
    height: 10px;
  }
}
