/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #22272e;
  color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
header {
  text-align: center;
  padding: 40px 0;
}

header h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #dd9710;
}

header p {
  font-size: 18px;
  margin-bottom: 20px;
}

.cta {
  display: inline-block;
  background-color: #347d39;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cta:hover {
  background-color: #215c28;
}

/* Section Styles */
section {
  padding: 40px 0;
}

section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #dd9710;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
}

li h3 {
  margin-bottom: 10px;
  color: #ffffff;
}

li p {
  color: #b0b0b0;
}

/* Card Styles */
.card {
  background-color: #3b4d33; /* Changed to deep green */
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin-bottom: 10px;
}

.card p {
  margin-bottom: 15px;
}

.card .cta {
  display: inline-block;
  background-color: #dd9710;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.card .cta:hover {
  background-color: #b37300;
}

/* Footer Styles */
footer {
  text-align: center;
  background-color: #22272e;
  padding: 20px 0;
  color: #b0b0b0;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 28px;
  }

  header p {
    font-size: 16px;
  }

  section h2 {
    font-size: 20px;
  }

  .cta {
    padding: 8px 16px;
  }
}
