.sidebar {
  width: 20%;
}

.sidebar-links {
  position: sticky;
  top: 200px;
}

.sidebar-links li {
  font-size: 21px;
  font-weight: 1000;
  margin-bottom: 20px;
  color: var(--green-color);
  list-style: none;
  transition: all 300ms ease-in;
}

.occur{
  color: var(--primary-color) !important;
  transform: translateX(10px);
}

.sidebar-links li:hover {
  transform: translateX(10px);
}
