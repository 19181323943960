/* Merged Stylesheet */

/* Common styles */

.hero-container {
  width: 100%;
  
  height: 100%;
  background: #1c2128;
  cursor: text;
  border-radius: 22px;
  display: flex;
  padding: 12px;
  color: rgb(108, 107, 106);
  align-items: center;
  user-select: none;
  justify-items: center;
  position: relative;
}

.hero-container .hero {
  background-color: lightblue;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.5s;
}

.hero-container .hero:hover {
  transform: scale(1.1);
}

.hero-container .dialog {
  position: absolute;
  top: 0;
  left: 0;
  background-color: lightcoral;
  padding: 20px;
  opacity: 0;
  transform: scale(0.8);
  transform-origin: top left;
  transition: opacity 0.5s, transform 0.5s;
}

.hero-container.dialog-open .dialog {
  opacity: 1;
  transform: scale(1);
}

.hero-container.dialog-open .hero {
  transform: scale(0.8);
}

.hidden {
  display: none;
}

/* Search bar styles */

.search-bar-container {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar-input-container {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 80%; /* Adjusted width for better responsiveness */
  max-width: 600px; /* Limit maximum width */
  border-radius: 22px;
  height: 70px;
}

.search-icon {
  color: rgb(173, 170, 168);
  font-size: 40px;
  margin-right: 20px;
}

.search-input {
  font-size: 30px;
  height: 100%;
  width: 100%;
  border: none;
  font-weight: bolder;
  outline: none;
  background: #1c2128;
  color: var(--primary-color);
  caret-color: var(--primary-color);
}

/* Media Query for Tablet and Mobile Screens */
@media only screen and (max-width: 768px) {
  .hero-container {
    height: 100%; /* Adjusted height for smaller screens */
    padding: 8px; /* Adjusted padding for smaller screens */
  }

  .search-bar-input-container {
    width: 90%; /* Adjusted width for smaller screens */
  }

  .search-icon {
    font-size: 30px; /* Adjusted font size for smaller screens */
  }

  .search-input {
    font-size: 20px; /* Adjusted font size for smaller screens */
  }
}
