.contacts{
    margin: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts h1{
    font-size: 30px;
    margin-bottom: 45px;
    color: white;
    font-weight: bold;
}

.contacts .container{
    display: flex;
}

.contact-container{
    width: 60px;
    margin: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: inset 6px 6px 8px rgb(0 0 0 / 50%), inset -4px -4px 15px rgb(255 255 255 / 10%), 5px 5px 15px rgb(0 0 0 / 50%), -5px -5px 15px rgb(255 255 255 / 10%);
}

.contact-container a span{
    font-size: 30px;
    color:var(--primary-color);
}