/* Adjusted for responsiveness */
.right-panel-container {
  padding: 10px;
  width: 100%;
}

.poetry-header,
.poetry-centralidea,
.poetry-title {
  padding: 10px;
  background: rgb(33, 42, 54);
  width: 100%;
  color: white;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Media Query for Tablet and Mobile Screens */
@media only screen and (max-width: 768px) {
  .right-panel-container {
    padding: 5px; /* Adjusted padding for smaller screens */
  }
  .poetry-header {
    text-align: center;
  }
  .poetry-header,
  .poetry-centralidea,
  .poetry-title {
    padding: 8px; /* Adjusted padding for smaller screens */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
}
