.book-detail-container {
  min-height: 300px;
  width: calc(100% - 20px);
  margin: 10px auto 0 auto;
  padding: 10px;
  background: #1c2128;
  display: flex;
  box-shadow: rgb(0, 0, 0, 0.5) 0px 5px 15px;
  border-radius: 10px;
  flex-direction: column;
  align-items: start;
}

.book-detail-container-inner {
  min-height: 300px;
  width: calc(100% - 20px);
  padding: 20px;
  display: flex;
  border-radius: 10px;
  align-items: start;
}

.book-cover-container {
  height: 250px;
  width: 230px;
}

.book-details {
  min-height: 250px;
  margin-left: 20px;
}

.book-title {
  width: fit-content;
  font-size: 1.7rem;
  font-family: UnifrakturCook, cursive;
  color: var(--primary-color);
  text-shadow: 2px 2px 4px #000000;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-genres {
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
}

.book-genre {
  width: fit-content;
  color: #864af9;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  text-shadow: 2px 2px 4px #000000;
  align-items: center;
  margin-right: 6px;
}

.book-description {
  margin-top: 2px;
  color: #adbac7;
  text-align: justify;
  font-weight: bolder;
  text-shadow: 2px 2px 4px #000000;
}

.book-stats {
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  gap: 10px;
  margin: 20px 0;
}

.read-book-button {
  background: #347d39;
  padding: 12px;
  border-radius: 12px;
  width: fit-content;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  margin: 10px 0 10px 0;
  user-select: none;
  cursor: pointer;
  color: white;
}
@media screen and (max-width: 768px) {
  .book-detail-container {
    flex-direction: column;
    align-items: center;
  }

  .book-detail-container-inner {
    flex-direction: column;
    align-items: center;
  }

  .book-cover-container {
    width: 200px;
  }

  .book-details {
    min-height: 200px;
    margin-left: 0;
    margin-top: 20px;
  }

  .book-title {
    font-size: 1.5rem;
  }

  .book-genres {
    align-items: center;
  }

  .book-genre {
    margin-right: 0;
    margin-bottom: 6px;
  }

  /* .book-stats {
    grid-template-columns: auto;
    width: 100%;
  } */

  .read-book-button {
    width: 100%;
  }
}
