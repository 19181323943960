/* Merged Stylesheet */

/* Common styles */

.floatingbtn {
  animation: 2.5s ease infinite angular-translation;
  position: fixed;
  height: 30px;
  width: 30px;
  right: 90%;
  transition: 500ms;
  border-radius: 50%;
  /* left: 220px; */
  top: 50%;
  transform: translateY(-50%);
  background: var(--github-green-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
}

.floatingbtn.open {
  left: 220px;
}

.floatingbtn.close {
  left: 10px;
}

.poetry-title {
  color: white;
  font-size: 1.5vw;
  font-weight: bold;
  margin-bottom: 10px;
}

.left-panel-container {
  position: sticky;
  left: 0;
  top: 60px;
  bottom: 0;
  transition: 500ms;
  display: flex;
  height: calc(100vh - 60px);
}

.left-panel-container.close {
  width: 0;
}

.left-panel-container.open {
  width: 300px;
}

.poetry-left-panel {
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
}

.poetry-left-panel.open {
  display: flex;
}

.poetry-left-panel.close {
  display: none;
}

@keyframes angular-translation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media only screen and (max-width: 768px) {
  /* Styles for screens smaller than 768px (tablets and desktops) */
  .left-panel-container{
    position: fixed;
    z-index: 999;
    background: #29313c;
    height: 100%;
  }
  
}
