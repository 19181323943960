.resume {
  min-height: 100vh;
  width: 100%;
  display: flex;
  top: 60px;
  justify-content: center;
  background: var(--intro-canvas);
}

.resume-content {
  width: 55%;
  height: fit-content;
}


.resume-content h1 {
  color: var(--primary-color);
  margin-bottom: 15px;
}

.container-content {
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 0.5px solid rgb(71, 71, 71);
}

.container-content .icon {
  margin-right: 28px;
  width: 70px;
}

.container-content .icon span {
  font-size: 60px;
  color: var(--primary-color);
}

.edu-name {
  font-size: 22px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.edu-course {
  font-size: 18px;
  font-weight: bold;
  color: var(--green-color);
}

.edu-trade {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.edu-aggregate,
.edu-place {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.edu-year {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
}

.experience {
  margin-top: 60px;
}

.exp-role {
  font-size: 18px;
  font-weight: bold;
  color: var(--green-color);
  margin-bottom: 10px;
}

.exp-duration {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 10px;
}
.exp-list {
  display: flex;
  flex-direction: column;
}
.exp-list li {
  transform: translateX(30px);
  color: white;
}

.skills {
  margin-top: 60px;
}

.skills .container-content {
  justify-content: center;
}
.inner-skill-container {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  align-items: center;
}

.skills div img {
  height: 60px;
  margin: 15px;
  transition: 0.5s;
  cursor: pointer;
}

.skills div img:hover {
  transform: scale(1.17);
}

.half-radius {
  border-radius: 50%;
}

.awards{
  margin-top: 60px;
}

.awards ul li{
  list-style: none;
  margin-bottom: 15px;
  margin-left: 20px;
  font-size: large;
  font-weight: bold !important;
  color: rgb(207, 202, 202);
}

.awards ul li::before{
  content: '✓';
  display: inline-block;
  margin-right: 35px;
  margin-left: -25px;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 20px;
}