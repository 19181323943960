/* Common styles */

.tilt-container {
  margin: 60px;
  width: 230px;
  height: 250px;
}

.books-heading {
  margin: 60px 0px;
  text-align: center;
  display: flex;
  text-shadow: 2px 2px 4px #000000;
  justify-content: space-around;
  align-items: center;
}

.books-heading h1 {
  font-size: 4rem;
  font-family: var(--normal-text-font);
  color: rgb(221, 221, 221);
}

.books-title {
  font-family: UnifrakturCook, cursive;
}

/* Book card styles */

.book-card {
  position: relative;
  padding: 8px 10px 10px 10px;
  width: 230px;
  background: #1c2128;
  height: 250px;
  text-align: center;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 300ms;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
  overflow: visible;
  box-shadow: rgb(0, 0, 0, 0.5) 0px 5px 15px;
}

.bottom-container {
  margin-top: 8px;
  padding: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background: var(--primary-color);
}

.book-container {
  position: absolute;
  transform: translate(-50%, -50px);
  left: 50%;
  right: 10px;
}

.book-card .line {
  width: 20px;
  height: 2px;
  left: 50%;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
}

/* Books content styles */

.books-content {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

/* Loading spinner styles */

.loading-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Submit button styles */

.submit-book {
  position: fixed;
  background: var(--primary-color);
  bottom: 10px;
  right: 10px;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

/* Quote container styles */

.quote-container {
  width: 40%;
}

.quote {
  font-size: 2rem;
  font-family: Caveat, cursive;
  text-shadow: 2px 2px 4px #000000;
  color: var(--primary-color);
}

.quote-author {
  display: block;
  color: white;
  font-size: 1.1rem;
  display: flex;
  justify-content: end;
}

/* Media Query for Tablet Screens */
@media only screen and (max-width: 768px) {
  .books-heading {
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
  }

  .submit-book .submit-text {
    display: none;
  }

  .books-heading h1 {
    font-size: 2.5rem;
  }

  .books-content {
    padding: 10px;
  }

  .tilt-container,
  .book-card,
  .book-container {
    width: 100%;
    max-width: 300px;
  }

  .quote-container {
    margin-top: 10px;
    width: 100%;
  }
  .quote {
    font-size: 1.5rem;
  }
}
